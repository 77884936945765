import React from "react";
import "../styles/about.css";
import { motion, useIsPresent } from "framer-motion";

export const AboutPage = () => {
  const isPresent = useIsPresent();
  return (
    <div className="aboutPage">
      <h1>About This Year's Merch</h1>
      <p>
        For NAIMUN LXII, we dug deep into our archives for inspiration. All of
        our flagship designs are based off of merchandise created for NAIMUN I
        (then known as NIMGA). This retro theme will even carry over to
        conference materials, like background guides.
      </p>
      <p>
        <span style={{ fontWeight: "bold" }}>
          Orders placed online can be picked up during conference weekend using
          the code sent to you after checkout.{" "}
        </span>{" "}
        It isn't just presales, though. The online store will sell exclusive
        items that won't be sold during the conference, so don't pass up the
        opportunity if something catches your eye.
      </p>
      <p>
        For questions regarding merchandise or your order, contact the Director
        of Operations, <a href="mailto:n.mukhin@modelun.org">Nik Mukhin</a>.{" "}
        {""}
        For technical questions or issues with the online store, contact the
        Director of Technology,{" "}
        <a href="mailto:l.ledlow@modelun.org">Leo Ledlow</a>.
      </p>
      <motion.div
        initial={{ scaleX: 1 }}
        animate={{ scaleX: 0, transition: { duration: 0.5, ease: "circOut" } }}
        exit={{ scaleX: 1, transition: { duration: 0.5, ease: "circIn" } }}
        style={{ originX: isPresent ? 1 : 0 }}
        className="privacy-screen"
      />
    </div>
  );
};
