import React, { useEffect, useState } from "react";
import { UserContext } from "../App";
import { getOrders } from "../helpers/firestore";
import { useNavigate } from "react-router-dom";
import "../styles/order.css";
import { motion, useIsPresent } from "framer-motion";
import { logout } from "../helpers/auth";
import OrderCard from "../components/orderCard";

export default function OrderPage() {
  const navigate = useNavigate();
  const isPresent = useIsPresent();
  const userData = React.useContext(UserContext);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (userData) {
      setTimeout(() => {
        try {
          getOrders(userData.orders).then((data) => {
            setOrders(data);
            setLoading(false);
          });
        } catch (error) {
          console.error(error);
          setOrders([]);
        }
      }, 500);
    } else {
      navigate("/login");
    }
  }, [userData]);

  return (
    <div className="orderPage">
      <div className="orderPageTitle orderPageHeader">Your Account</div>
      <div className="orderList">
        {loading && <div className="orderCard orderCardLoader loading"></div>}
        {orders.length === 0 && !loading && (
          <div className="emptyOrdersContainer">
            <div className="orderPageTitle" style={{ textAlign: "center" }}>
              We can't find any orders on your account.
            </div>
            <div className="orderActionContainer">
              <button
                className="orderActionButton"
                onClick={() => navigate("/")}
                style={{ display: "flex", justifyContent: "center" }}
              >
                Start Shopping
              </button>
            </div>
          </div>
        )}
        {orders.length !== 0 && (
          <>
            {orders.map((order) => (
              <OrderCard order={order} key={order.id} />
            ))}
          </>
        )}
        <button
          id="signOutButton"
          onClick={() => {
            logout();
            navigate("/login");
          }}
        >
          {" "}
          Logout{" "}
        </button>
      </div>
      <motion.div
        initial={{ scaleX: 1 }}
        animate={{ scaleX: 0, transition: { duration: 0.5, ease: "circOut" } }}
        exit={{ scaleX: 1, transition: { duration: 0.5, ease: "circIn" } }}
        style={{ originX: isPresent ? 1 : 0 }}
        className="privacy-screen"
      />
    </div>
  );
}
