import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

const storage = getStorage();

const thumbnailRef = ref(storage, "products/thumbnails");
const imageRef = ref(storage, "products/full");

export const uploadImage = async (file, product, index) => {
  // there's a more serious issue here that we don't have time to fix so this works for now
  let storageRef = ref(imageRef, `${product.id}-${index + 1}`);
  await uploadBytes(storageRef, file).catch((error) => {
    console.error(error);
  });
  const url = await getDownloadURL(storageRef);
  // regex to strip the url to the filename
  const regex = /%2..*%2F(.*?)\?alt/;
  const match = url.match(regex);
  const imageName = match ? match[1] : null;
  //return the filename
  return imageName;
};
