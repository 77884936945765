import React, { useState, useContext, useEffect } from "react";
import "../styles/header.css";
import { useNavigate, useLocation } from "react-router-dom";
import {
  IoBasketOutline,
  IoClose,
  IoChevronForwardOutline,
  IoAdd,
  IoRemove,
  IoTrash,
} from "react-icons/io5";
import { CartContext } from "../helpers/CartContext";
import { motion, AnimatePresence } from "framer-motion";
import { UserContext } from "../App";

export const Header = () => {
  const navigate = useNavigate();
  const { cart, removeFromCart, changeQuantity } = useContext(CartContext);
  const user = useContext(UserContext);
  const [togglePopout, setTogglePopout] = useState(false);
  const location = useLocation();

  //defines path where the popup won't appear on cart change
  const restrictedPaths = [
    "login",
    "signup",
    "checkout",
    "orders",
    "admin",
    "reset",
    "code",
    "success",
    "404",
  ];

  useEffect(() => {
    if (restrictedPaths.includes(location.pathname.split("/")[1])) {
      return;
    }
    setTogglePopout(true);
  }, [cart]);

  const findIndex = (product, size = null) => {
    for (let i = 0; i < cart.length; i++) {
      //check if the product has a size bcz some products don't have sizes
      if (size) {
        //check if our item is in the cart with the same size
        if (cart[i].product.id === product.id && cart[i].size === size) {
          //return the index
          return i;
        }
      } else {
        //check if our item is in the cart
        if (cart[i].product.id === product.id) {
          //return the index
          return i;
        }
      }
    }
    return -1;
  };

  const handleChange = (product, action, size) => {
    const index = findIndex(product, size);
    const newQuantity =
      action === "add" ? cart[index].quantity + 1 : cart[index].quantity - 1;
    if (newQuantity > 0) {
      changeQuantity(index, newQuantity);
    } else {
      removeFromCart(index);
    }
  };

  const handleRemove = (product) => {
    const index = findIndex(product);
    removeFromCart(index);
  };

  return (
    <div className="header">
      <div className="headerLogo" onClick={() => navigate("/")}>
        NAIMUN
      </div>
      <div className="headerAction">
        <IoBasketOutline
          className="headerActionIcon"
          onClick={() => {
            setTogglePopout(true);
          }}
          color="#46a0d7"
          size="2.5em"
        />
        {cart.length > 0 && (
          <div className="headerCartBadge">
            {cart.reduce((acc, item) => acc + item.quantity, 0)}
          </div>
        )}
      </div>
      <AnimatePresence>
        {togglePopout && (
          <motion.div
            className="privacyScreen"
            initial={{ opacity: 0 }}
            exit={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            onClick={() => setTogglePopout(false)}
          >
            <motion.div
              className="headerActionPopout"
              initial={{ opacity: 0, y: -100 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -100 }}
              onClick={(e) => e.stopPropagation()}
            >
              <div
                className="headerActionPopoutSection"
                id="headerPopoutTopWrapper"
              >
                <div
                  className="headerSectionContent"
                  id=" headerPopoutTopContent"
                >
                  <div className="headerPopoutSectionTitle">
                    Your Cart - $
                    {cart
                      .reduce(
                        (acc, item) => acc + item.quantity * item.product.price,
                        0
                      )
                      .toFixed(2)}
                  </div>
                  <IoClose
                    className="headerPopoutClose"
                    onClick={() => setTogglePopout(false)}
                    color="#040709"
                    size="1.5em"
                  />
                </div>
              </div>
              <div
                className="headerActionPopoutSection"
                id="headerPopoutCartWrapper"
              >
                <div
                  className="headerSectionContent"
                  style={{
                    flexDirection: "column",
                    overflowY: "auto",
                    overflowX: "hidden",
                    paddingBlock: "0",
                    height: "100%",
                    justifyContent: "flex-start",
                  }}
                >
                  {cart.length === 0 ? (
                    <div className="headerPopoutEmptyCart">
                      Your cart is empty
                    </div>
                  ) : (
                    cart.map((product) => (
                      <div
                        className="headerPopoutCartItem"
                        key={product.product.id + product.size}
                      >
                        <img
                          className="headerPopoutCartItemImg"
                          src={`https://firebasestorage.googleapis.com/v0/b/naimun-merch.appspot.com/o/products%2Fthumbnails%2F${product.product.image}_200x200?alt=media`}
                          alt={product.product.name}
                        />
                        <div className="headerPopoutCartItemDetails">
                          <div className="popoutItemHeader">
                            <div className="headerPopoutCartItemName">
                              {product.product.name}
                            </div>
                            <div className="headerPopoutCartItemPrice">
                              $
                              {(
                                product.product.price * product.quantity
                              ).toFixed(2)}
                            </div>
                          </div>
                          <div className="popoutItemBody">
                            <div className="headerPopoutCartItemQuantity">
                              Quantity: {product.quantity}
                            </div>
                            {product.size !== "none" && (
                              <div className="headerPopoutCartItemSize">
                                Size: {product.size}
                              </div>
                            )}
                          </div>
                          <div className="headerPopoutCartItemModify">
                            <button
                              aria-label="Add one of Item"
                              onClick={() =>
                                handleChange(
                                  product.product,
                                  "add",
                                  product.size
                                )
                              }
                            >
                              <IoAdd />
                            </button>
                            <button
                              aria-label="Remove one of Item"
                              onClick={() =>
                                handleChange(
                                  product.product,
                                  "subtract",
                                  product.size
                                )
                              }
                            >
                              <IoRemove />
                            </button>
                            <button
                              aria-label="Remove Item from cart"
                              onClick={() => handleRemove(product.product)}
                            >
                              <IoTrash />
                            </button>
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
              <div
                className="headerActionPopoutSection"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (cart.length === 0) {
                    setTogglePopout(false);
                    if (user) {
                      navigate("/orders");
                    } else {
                      navigate("/login");
                    }
                  }
                }}
              >
                {cart.length === 0 ? (
                  <div className="headerSectionContent">
                    <div className="headerPopoutSectionTitle">Your Account</div>
                    <IoChevronForwardOutline
                      className="headerPopoutSectionIcon"
                      color="#040709"
                      size="1.5em"
                    />
                  </div>
                ) : (
                  <div className="headerSectionContent">
                    <button
                      className="headerPopoutCheckoutButton"
                      onClick={() => {
                        user ? navigate("/checkout") : navigate("/login");
                        setTogglePopout(false);
                      }}
                    >
                      Checkout
                    </button>
                    <button
                      className="headerPopoutOrdersButton"
                      onClick={() => {
                        navigate("/orders");
                        setTogglePopout(false);
                      }}
                    >
                      View Orders
                    </button>
                  </div>
                )}
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Header;
