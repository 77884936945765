import * as React from "react";
import { AnimatePresence } from "framer-motion";
import { useLocation, useRoutes } from "react-router-dom";
import HomePage from "./screens/HomePage";
import BrowsePage from "./screens/BrowsePage";
import ContactPage from "./screens/ContactPage";
import OrderPage from "./screens/OrderPage";
import LoginPage from "./screens/LoginPage";
import RegisterPage from "./screens/RegisterPage";
import ProductPage from "./screens/ProductPage";
import CheckoutPage from "./screens/CheckoutPage";
import CodePage from "./screens/CodePage";
import OrderDetailsPage from "./screens/OrderDetailsPage";
import AdminPage from "./screens/AdminPage";
import ResetPage from "./screens/Resetpage";
import { SizingPage } from "./screens/SizingPage";
import { PrivacyPage } from "./screens/PrivacyPage";
import { RefundPage } from "./screens/RefundPage";
import { SuccessPage } from "./screens/SuccessPage";
import { AboutPage } from "./screens/AboutPage";
import { NotFound } from "./screens/404";
import "./styles/styles.css";
import Header from "./components/header";
import { auth } from "./helpers/auth";
import { onAuthStateChanged } from "firebase/auth";
import { getUser } from "./helpers/firestore";
import { CartProvider } from "./helpers/CartContext";
import { CookiesProvider, useCookies } from "react-cookie";

export const UserContext = React.createContext(null);

export default function App() {
  const [cart, setCart] = React.useState(["1", "2", "3"]);
  const [cookies, setCookie] = useCookies(["cart"]);
  const [user, setUser] = React.useState(null);
  const [userData, setUserData] = React.useState(null);

  /* check if user is logged in and update their cart*/
  React.useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        getUser(user.uid).then((data) => {
          setUserData(data);
          try {
            setCart(data.cart);
          } catch (error) {
            console.error(error);
          }
        });
      } else {
        setUserData(null);
        setCart([]);
      }
    });
  }, []);

  const element = useRoutes([
    {
      path: "/",
      element: <HomePage />,
    },
    {
      path: "/browse",
      element: <BrowsePage />,
    },
    {
      path: "/contact",
      element: <ContactPage />,
    },
    {
      path: "/orders",
      element: <OrderPage />,
    },
    {
      path: "/orders/:id",
      element: <OrderDetailsPage />,
    },
    {
      path: "/orders/pickup/:id",
      element: <CodePage />,
    },
    {
      path: "/login",
      element: <LoginPage />,
    },
    {
      path: "/register",
      element: <RegisterPage />,
    },
    {
      path: "/product/:id",
      element: <ProductPage />,
    },
    {
      path: "/checkout",
      element: <CheckoutPage />,
    },
    {
      path: "/success",
      element: <SuccessPage />,
    },
    {
      path: "/about",
      element: <AboutPage />,
    },
    {
      path: "/admin",
      element: <AdminPage />,
    },
    {
      path: "/reset",
      element: <ResetPage />,
    },
    {
      path: "/privacy",
      element: <PrivacyPage />,
    },
    {
      path: "/refunds",
      element: <RefundPage />,
    },
    {
      path: "/sizing",
      element: <SizingPage />,
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ]);

  const location = useLocation();

  if (!element) {
    return null;
  }

  return (
    <CookiesProvider>
      <UserContext.Provider value={userData}>
        <CartProvider value={{ cart: cart }}>
          <Header />
          <AnimatePresence mode="wait" initial={false}>
            {React.cloneElement(element, {
              key: location.pathname,
            })}
          </AnimatePresence>
        </CartProvider>
      </UserContext.Provider>
    </CookiesProvider>
  );
}
