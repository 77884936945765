import React from "react";
import "../styles/about.css";
import { motion, useIsPresent } from "framer-motion";

export const PrivacyPage = () => {
  const isPresent = useIsPresent();
  return (
    <div className="aboutPage">
      <h1>Privacy Policy for NAIMUN LXII Merchandise Store</h1>
      <div className="aboutContent">
        <p>
          <strong>Effective Date:</strong> September 5, 2024
        </p>

        <h2>1. Introduction</h2>
        <p>
          Welcome to NAIMUN LXII's Merchandise Store! Your privacy is important
          to us. This Privacy Policy explains how we collect, use, disclose, and
          safeguard your information when you visit our online store, create an
          account, and make purchases. By using our website, you agree to the
          terms of this Privacy Policy.
        </p>

        <h2>2. Information We Collect</h2>

        <h3>2.1 Personal Information</h3>
        <p>
          When you create an account or make a purchase, we may collect personal
          information such as:
        </p>
        <ul>
          <li>Name</li>
          <li>Email address</li>
          <li>Billing address</li>
          <li>Phone number</li>
          <li>
            Payment information (processed securely through our{" "}
            <a href="https://stripe.com/privacy">payment processor</a>)
          </li>
        </ul>

        <h3>2.2 Order Information</h3>
        <p>
          We may retain records of your orders, including details of the items
          purchased, the date of the purchase, and any relevant order
          preferences.
        </p>

        <h3>2.3 Usage Data</h3>
        <p>
          We collect information about your interactions with our website,
          including IP address, browser type, pages visited, and time spent on
          our site. This helps us improve our services and provide a better
          shopping experience.
        </p>

        <h2>3. How We Use Your Information</h2>

        <h3>3.1 Account Management</h3>
        <p>
          Your personal information is used to manage your account, including
          enabling you to access your order history, save items for later, and
          update your details.
        </p>

        <h3>3.2 Order Processing</h3>
        <p>
          We use your information to process and fulfill your orders, including
          handling payments and arranging for pickups. We may also send you
          updates via email about your order status.
        </p>

        <h3>3.3 Customer Support</h3>
        <p>
          We use your information to provide customer support and respond to
          inquiries or issues related to your orders or account.
        </p>

        <h2>4. How We Share Your Information</h2>

        <h3>4.1 Service Providers</h3>
        <p>
          We may share your information with third-party service providers who
          assist us with payment processing, order fulfillment, and marketing.
          These providers are obligated to protect your information and use it
          only for the purposes specified by us.
        </p>

        <h3>4.2 Legal Requirements</h3>
        <p>
          We may disclose your information if required by law or in response to
          valid requests by public authorities, such as to comply with a
          subpoena or other legal process.
        </p>

        <h3>4.3 Business Transfers</h3>
        <p>
          In the event of a merger, acquisition, or sale of assets, your
          information may be transferred as part of the transaction. We will
          notify you of any such changes and how your information will be
          handled.
        </p>

        <h2>5. Data Security</h2>
        <p>
          We implement reasonable security measures to protect your personal
          information from unauthorized access, disclosure, alteration, and
          destruction. However, no method of transmission over the internet or
          electronic storage is 100% secure, so we cannot guarantee absolute
          security.
        </p>

        <h2>6. Your Choices</h2>

        <h3>6.1 Access and Update</h3>
        <p>
          You can access and update your account information by logging into
          your account on our website. If you need assistance, you can contact
          our customer support team.
        </p>

        <h2>7. Children's Privacy</h2>
        <p>
          Our website is not intended for children under the age of 13. We do
          not knowingly collect personal information from children under 13. If
          we become aware that we have collected such information, we will take
          steps to delete it.
        </p>

        <h2>8. Changes to This Privacy Policy</h2>
        <p>
          We may update this Privacy Policy from time to time. We will notify
          you of any significant changes by posting the new policy on our
          website with an updated effective date. Your continued use of our
          website after any changes constitutes your acceptance of the updated
          policy.
        </p>

        <h2>9. Contact Us</h2>
        <p>
          If you have any questions or concerns about this Privacy Policy or our
          data practices, please contact us at:
        </p>
        <p>
          <strong>NAIMUN LXII Merchandise Store</strong>{" "}
        </p>
        <p>
          Email: <a href="mailto:tech@modelun.org">tech@modelun.org</a>
        </p>
        <p>Address: 3220 N St NW #176, Washington, D.C. 20007</p>

        <p>Thank you for shopping with NAIMUN LXII!</p>
      </div>
      <motion.div
        initial={{ scaleX: 1 }}
        animate={{ scaleX: 0, transition: { duration: 0.5, ease: "circOut" } }}
        exit={{ scaleX: 1, transition: { duration: 0.5, ease: "circIn" } }}
        style={{ originX: isPresent ? 1 : 0 }}
        className="privacy-screen"
      />
    </div>
  );
};
