import React from "react";
import { motion } from "framer-motion";
import "../styles/orderCard.css";
import { useNavigate } from "react-router";

export default function OrderCard({ order }) {
  const navigate = useNavigate();
  const date = new Date(order.date.seconds * 1000);
  const dateOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const quantity = order.items.reduce((acc, item) => acc + item.quantity, 0);

  return (
    <motion.div
      className="orderCard"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="orderCardBody">
        <div className="orderCardHeader">
          <div className="orderCardTitle">Order {order.id}</div>
          <div className="orderCardDetails">
            {date.toLocaleDateString("en-US", dateOptions)}
            <span className="orderCardPrice">{" - $" + order.total}</span>
          </div>
          <div className="orderCardQuantity">
            {quantity} item{quantity > 1 ? "s" : ""}
          </div>
        </div>
        <div className="orderCardActions">
          <button
            id="createCodeButton"
            onClick={() => navigate(`/orders/pickup/${order.id}`)}
          >
            Get Pickup Code
          </button>
          <button
            id="viewOrderButton"
            onClick={() => navigate(`/orders/${order.id}`)}
          >
            View Order
          </button>
          <button
            id="cancelOrderButton"
            onClick={() =>
              (window.location.href = `mailto:l.ledlow@modelun.org?cc=n.mukhin@modelun.org&subject=%5BOrder%20${order.id}%5D%20Cancellation%20Request`)
            }
          >
            Cancel Order
          </button>
        </div>
      </div>
      <div className="orderCardImage">
        <img
          src={`https://firebasestorage.googleapis.com/v0/b/naimun-merch.appspot.com/o/products%2Ffull%2F${order.items[0].product.image}_1728x2160?alt=media`}
          style={{
            backgroundImage: `url("https://firebasestorage.googleapis.com/v0/b/naimun-merch.appspot.com/o/products%2Fthumbnails%2F${order.items[0].product.image}_200x200?alt=media") no-repeat center center`,
          }}
          alt={order.items[0].product.name}
        />
      </div>
    </motion.div>
  );
}
