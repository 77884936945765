import React from "react";
import "../styles/404.css";
import { motion, useIsPresent } from "framer-motion";

export const NotFound = () => {
  const isPresent = useIsPresent();
  return (
    <>
      <div className="notFound">
        <h1>404</h1>
        <h2>We couldn't find that page!</h2>
      </div>
      <motion.div
        initial={{ scaleX: 1 }}
        animate={{ scaleX: 0, transition: { duration: 0.5, ease: "circOut" } }}
        exit={{ scaleX: 1, transition: { duration: 0.5, ease: "circIn" } }}
        style={{ originX: isPresent ? 1 : 0 }}
        className="privacy-screen"
      />
    </>
  );
};
