import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../App";
import "../styles/admin.css";
import { getAnalytics, getProducts, createItem } from "../helpers/firestore";
import { IoPencilSharp, IoAdd } from "react-icons/io5";
import ItemUpdateForm from "../components/itemUpdateForm";

export default function AdminPage() {
  const user = useContext(UserContext);
  const [summary, setSummary] = useState(null);
  const [products, setProducts] = useState(null);
  const [analytics, setAnalytics] = useState(null);
  const [editing, setEditing] = useState(null);
  const navigate = useNavigate();

  const createNewItem = () => {
    createItem().then((data) => {
      setEditing(data);
    });
  };

  useEffect(() => {
    if (user && user.role !== "admin") {
      navigate("/login");
      console.error("User not logged in");
      console.error(user);
    } else {
      getAnalytics().then((data) => {
        setSummary(data.summary);
        setAnalytics(data.analytics);
      });
      getProducts().then((data) => {
        setProducts(data);
      });
    }
  }, [user, navigate]);

  if (!user || user.role !== "admin") {
    return (
      <div className="adminPage">
        <h1>Are you supposed to be here?</h1>
      </div>
    );
  }

  return (
    <div className="adminPage">
      <div className="adminContainer adminSummary">
        <div className="adminSummaryContent">
          <h1>Nik's fun amazing admin portal</h1>
          {summary && (
            <div className="adminSummaryDataRow">
              <div className="adminSummaryDatapoint">
                <div className="adminSummaryValue">{summary.orderNum}</div>
                <div className="adminSummaryLabel">Total Orders</div>
              </div>
              <div className="adminSummaryDatapoint">
                <div className="adminSummaryValue">{summary.quantityNum}</div>
                <div className="adminSummaryLabel">Items Ordered</div>
              </div>
              <div className="adminSummaryDatapoint">
                <div className="adminSummaryValue">${summary.total}</div>
                <div className="adminSummaryLabel">Total Value</div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="adminActions">
        <div className="adminContainer adminAction">
          {analytics && (
            <div className="adminActionContent">
              <div className="adminActionList">
                {analytics.map((item) => (
                  <div
                    key={item.id}
                    className="orderCardLineItem adminLineItem"
                  >
                    <div className="orderDetailsCardImage">
                      <img
                        src={`https://firebasestorage.googleapis.com/v0/b/naimun-merch.appspot.com/o/products%2Fthumbnails%2F${item.image}_200x200?alt=media`}
                        alt={item.name}
                      />
                    </div>
                    <div className="adminActionItemContent">
                      <div className="adminActionItemName">{item.name}</div>
                      <div className="adminActionItemQuantity">
                        {item.quantity} sold across {item.numOrders} orders
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="adminContainer adminAction adminModify">
          {products &&
            products.map((product) => (
              <div
                key={product.id}
                className="adminModifyButton"
                style={{
                  background: `url(https://firebasestorage.googleapis.com/v0/b/naimun-merch.appspot.com/o/products%2Fthumbnails%2F${product.image}_200x200?alt=media) no-repeat scroll 0 0 transparent`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
                onClick={() => setEditing(product)}
              >
                <IoPencilSharp />
              </div>
            ))}
          <div
            key={"addItem"}
            className="adminModifyButton"
            onClick={() => createNewItem()}
          >
            <IoAdd />
          </div>
        </div>
      </div>
      {editing && <ItemUpdateForm item={editing} updateEditing={setEditing} />}
    </div>
  );
}
