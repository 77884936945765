import React, { useState, useEffect, useContext } from "react";
import "../styles/checkout.css";
import { AnimatePresence, motion, useIsPresent } from "framer-motion";
import { CartContext } from "../helpers/CartContext";
import { UserContext } from "../App";
import {
  IoChevronDown,
  IoChevronUp,
  IoCheckmark,
  IoClose,
} from "react-icons/io5";
import { TailSpin } from "react-loader-spinner";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "../components/checkoutForm";
import { getDiscountCodes } from "../helpers/firestore";
import { saveOrder } from "../helpers/firestore";
import { useDetectAdBlock } from "adblock-detect-react";
import { checkoutStart } from "../helpers/analytics";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || "");

export default function CheckoutPage() {
  const user = useContext(UserContext);
  const { cart, clearCart } = useContext(CartContext);
  const [total, setTotal] = useState(0);
  const [tax, setTax] = useState(0);
  const [fees, setFees] = useState(0);
  const [finalTotal, setFinalTotal] = useState(1099);
  const [finalTotalDisplay, setFinalTotalDisplay] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [showDiscount, setShowDiscount] = useState(false);
  const [discountStatus, setDiscountStatus] = useState("standby");
  const [discountCode, setDiscountCode] = useState("");
  const isPresent = useIsPresent();
  const [discountCodes, setDiscountCodes] = useState([]);
  const adBlockDetected = useDetectAdBlock();
  const [showTaxExempt, setShowTaxExempt] = useState(false);
  const [taxExempt, setTaxExempt] = useState(false);

  const appearance = {
    theme: "stripe",
    variables: {
      colorText: "#040709",
      colorPrimary: "#46a0d7",
      colorBackground: "#fff",
      fontFamily: "century-gothic",
      colorSuccess: "#46a0d7",
    },
    rules: {
      ".Label": {
        fontFamily: "century-gothic",
      },
    },
  };

  const options = {
    mode: "payment",
    amount: finalTotal,
    currency: "usd",
    appearance: appearance,
    fonts: [
      {
        src: `url("https://use.typekit.net/af/78c6b9/00000000000000007735ba1f/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191")`,
        family: "century-gothic",
      },
      {
        src: `url("https://use.typekit.net/af/40bdad/00000000000000007735ba11/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191")`,
        family: "century-gothic-bold",
      },
    ],
  };

  useEffect(() => {
    if (!user) {
      window.location.href = "/login";
    }
    getDiscountCodes().then((codes) => {
      setDiscountCodes(codes);
    });
    checkoutStart(cart);
    if (user.taxExempt) {
      setTaxExempt(true);
    }
  }, []);

  useEffect(() => {
    let total = 0;
    cart.forEach((item) => {
      total += item.product.price * item.quantity;
    });
    if (total === 0) {
      console.error("Total is 0");
      return;
    }
    //take percent discount off total
    const finalTotalCalc =
      //subtotal
      total * (1 - discount) +
      //6% sales tax, dc standard
      (taxExempt ? 0 : total * (1 - discount) * 0.06) +
      //2.9% + 30 cent fee from stripe
      (total * (1 - discount) * 0.029 + 0.3);
    setTotal(Number(parseFloat(total)).toFixed(2));
    //read tax exempt status and disable tax if true
    {
      taxExempt
        ? setTax(0)
        : setTax(Number(parseFloat(total * (1 - discount) * 0.06)).toFixed(2));
    }
    //stripe minimum amount is 30 cents
    if (finalTotalCalc > 0.3) {
      //convert to two digit for display
      setFinalTotalDisplay(finalTotalCalc.toFixed(2));
      //stripe only works in cents, so we get the cents and drop all fractional values
      setFinalTotal(Math.trunc(finalTotalCalc * 100));
      setFees(
        Number(parseFloat(total * (1 - discount) * 0.029 + 0.3)).toFixed(2)
      );
    } else {
      //default to 1099 if total is less than 30 cents, because stripe minimum is 30 cents
      setFinalTotal(1099);
      setFinalTotalDisplay(0);
      setFees(0);
    }
  }, [cart, discount, taxExempt]);

  const handleDiscount = async () => {
    setDiscount(0);
    setDiscountStatus("loading");
    if (discountCode === "") {
      setDiscountStatus("error");
      return;
    }
    try {
      //check if code is in list
      const code = discountCodes.find((code) => code.code === discountCode);
      if (!code) {
        setDiscountStatus("error");
        return;
      }
      const userEmail = user.email;
      //check if user email is in the list of emails that can use the code
      if (userEmail.includes(code.email)) {
        setDiscount(code.discount);
        setDiscountStatus("success");
        return;
      }
      setDiscountStatus("error");
    } catch (error) {
      console.error(error);
      setDiscountStatus("error");
    }
  };

  return (
    <>
      <Elements stripe={stripePromise} options={options}>
        <div className="checkoutPage">
          {showTaxExempt && (
            <div className="adblockPopup">
              <div className="adblockPopupText">
                <h1>Tax Exemption</h1>
                <strong>
                  Please note that we are only able to remove sales tax for
                  organizations that are tax-exempt in the District of Columbia.
                </strong>
                <p>
                  To be exempted from sales tax, please send us an email at{" "}
                  <a href="mailto:l.ledlow@modelun.org?subject=NAIMUN%20Merchandise%20Tax%20Exemption&body=Account%20email%20address%3A">
                    l.ledlow@modelun.org
                  </a>
                  . Kindly include the email address associated with your
                  account, and attatch the relevant tax exemption documentation.
                </p>
                <button onClick={() => setShowTaxExempt(false)}>Close</button>
              </div>
            </div>
          )}
          {adBlockDetected && (
            <div className="adblockPopup">
              <div className="adblockPopupText">
                <h1>We've detected that you're using an adblocker.</h1>
                <p>
                  Though we don't use ads, adblockers can interfere with the
                  checkout process.
                </p>
                <p>
                  Please disable your adblocker and refresh the page to
                  continue.
                </p>
              </div>
            </div>
          )}
          {finalTotalDisplay > 0.5 ? (
            <CheckoutForm
              finalTotal={finalTotal}
              couponCode={discountCode}
              items={cart}
            />
          ) : finalTotalDisplay === 0 ? (
            <div className="checkoutBackupButton">
              <button
                onClick={() => {
                  saveOrder(cart);
                  clearCart();
                  window.location.href = "/success";
                }}
              >
                Place Order
              </button>
            </div>
          ) : null}
          <div className="checkoutSummary">
            <div className="checkoutHeader">
              Your order{" "}
              {cart.length > 0
                ? `(${cart.reduce(
                    (acc, item) => acc + item.quantity,
                    0
                  )} items)`
                : ""}
            </div>
            <div className="summaryThumbnails">
              {cart.map((item) => (
                <img
                  src={`https://firebasestorage.googleapis.com/v0/b/naimun-merch.appspot.com/o/products%2Fthumbnails%2F${item.product.image}_200x200?alt=media`}
                  alt={item.name}
                  className="summaryThumbnail"
                  id={item.product.id}
                />
              ))}
            </div>
            <div className="summaryTotal">
              <div className="summaryTotalSection">
                <div className="totalHeader">Subtotal</div>
                <div className="totalAmount">
                  <span>${total}</span>
                </div>
              </div>
              {discount !== 0 && (
                <div className="summaryTotalSection">
                  <div className="totalHeader">Discount</div>
                  <div className="totalAmount">
                    <span>- ${(total * discount).toFixed(2)}</span>
                  </div>
                </div>
              )}
              <div className="summaryTotalSection">
                <div className="totalHeader">Sales Tax</div>
                <div className="totalAmount">
                  <span>${tax}</span>
                </div>
              </div>
              <div className="summaryTotalSection">
                <div
                  className="totalHeader"
                  style={{ color: "#46a0d7", cursor: "pointer" }}
                  onClick={() => setShowTaxExempt(true)}
                >
                  Tax exempt?
                </div>
              </div>
              <div className="summaryTotalSection">
                <div className="totalHeader">Fees</div>
                <div className="totalAmount">
                  <span>${fees}</span>
                </div>
              </div>
              <div className="summaryTotalHR"></div>
              <div
                className="summaryTotalSection"
                style={{ fontWeight: "bold" }}
              >
                <div className="totalHeader">Estimated Total</div>
                <div className="totalAmount">
                  <span>${finalTotalDisplay}</span>
                </div>
              </div>
              <div className="summaryTotalHR"></div>
              <div
                className="summaryTotalSection"
                style={{ fontWeight: "bold", cursor: "pointer" }}
                onClick={() => setShowDiscount(!showDiscount)}
              >
                <div className="totalHeader">Have a discount code?</div>
                {showDiscount ? <IoChevronUp /> : <IoChevronDown />}
              </div>
              <AnimatePresence>
                {showDiscount && (
                  <motion.div
                    key={"discountCodeInput"}
                    initial={{ scaleY: 0, originY: 0 }}
                    animate={{ scaleY: 1, originY: 1 }}
                    exit={{ scaleY: 0, originY: 0, height: 0 }}
                    delay={0.5}
                    className="discountSection"
                  >
                    <input onChange={(e) => setDiscountCode(e.target.value)} />
                    {discountStatus === "standby" ? (
                      <button onClick={handleDiscount}>Apply</button>
                    ) : discountStatus === "loading" ? (
                      <button
                        onClick={handleDiscount}
                        id="checkoutDiscountLoad"
                      >
                        <TailSpin
                          color="#f7fbfd"
                          wrapperStyle={{
                            width: "100%",
                            justifyContent: "center",
                          }}
                        />
                      </button>
                    ) : discountStatus === "success" ? (
                      <button onClick={handleDiscount}>
                        <IoCheckmark />
                      </button>
                    ) : discountStatus === "error" ? (
                      <button
                        onClick={handleDiscount}
                        style={{ backgroundColor: "#d64747" }}
                      >
                        <IoClose />
                      </button>
                    ) : null}
                  </motion.div>
                )}
              </AnimatePresence>
              <div className="summaryTotalSection" id="checkoutPolicies">
                <a href="/refunds" className="checkoutLink">
                  Refund Policy
                </a>
                <a href="/privacy" className="checkoutLink">
                  Privacy Policy
                </a>
              </div>
            </div>
          </div>
        </div>
      </Elements>
      <motion.div
        initial={{ scaleX: 1 }}
        animate={{ scaleX: 0, transition: { duration: 0.5, ease: "circOut" } }}
        exit={{ scaleX: 1, transition: { duration: 0.5, ease: "circIn" } }}
        style={{ originX: isPresent ? 1 : 0 }}
        className="privacy-screen"
      />
    </>
  );
}
