import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../App";
import { Formik } from "formik";
import { signIn } from "../helpers/auth";
import { motion, useIsPresent } from "framer-motion";
import "../styles/login.css";
import { IoLogoGoogle, IoLogoApple } from "react-icons/io5";
import * as Yup from "yup";
import { googleSignIn } from "../helpers/auth";

export default function LoginPage() {
  const navigate = useNavigate();
  const user = React.useContext(UserContext);
  const [error, setError] = useState(null);
  const isPresent = useIsPresent();

  useEffect(() => {
    if (user) {
      navigate("/orders");
    }
  }, [user]);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
    password: Yup.string().required("Required"),
  });

  return (
    <div className="loginPage">
      <div className="loginPageTitle">Login to your account</div>
      <Formik
        initialValues={{ email: "", password: "" }}
        validationSchema={LoginSchema}
        onSubmit={async (values) => {
          try {
            const loginError = await signIn(values.email, values.password);
            if (loginError !== undefined) {
              setError(loginError);
              return;
            } else {
              navigate("/orders");
            }
          } catch (error) {
            console.error(error);
            setError("Invalid email or password");
          }
        }}
      >
        {(props) => (
          <form className="loginForm" onSubmit={props.handleSubmit}>
            <div className="loginFormLabel">Email</div>
            <input
              className="loginFormInput"
              name="email"
              type="email"
              placeholder="example@modelun.org"
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              value={props.values.email}
              required
            />
            {props.errors.email && (
              <div className="loginFormError">{props.errors.email}</div>
            )}
            <div className="loginFormLabel">Password</div>
            <input
              className="loginFormInput"
              name="password"
              type="password"
              placeholder="••••••••"
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              value={props.values.password}
              required
            />
            {props.errors.password && (
              <div className="loginFormError">{props.errors.password}</div>
            )}
            <div
              className="loginFormForgotPassword"
              onClick={() => {
                navigate("/reset");
              }}
            >
              Forgot password?
            </div>
            <div className="loginFormError">{error}</div>
            <button type="submit" className="loginFormButton">
              Continue
            </button>
            <div className="loginFormRegister">
              Don't have an account?{" "}
              <span
                onClick={() => {
                  navigate("/register");
                }}
                className="loginFormRegisterLink"
              >
                Sign up
              </span>
            </div>
            <div className="loginFormDivider">OR</div>
            <button
              className="loginFormButton loginAltButton"
              onClick={(e) => {
                e.preventDefault();
                googleSignIn();
              }}
            >
              <IoLogoGoogle className="loginFormButtonIcon" />
              Sign in with Google
            </button>
            {/*<button className="loginFormButton loginAltButton">
              <IoLogoApple className="loginFormButtonIcon" />
              Sign in with Apple
            </button>*/}
          </form>
        )}
      </Formik>
      <motion.div
        initial={{ scaleX: 1 }}
        animate={{ scaleX: 0, transition: { duration: 0.5, ease: "circOut" } }}
        exit={{ scaleX: 1, transition: { duration: 0.5, ease: "circIn" } }}
        style={{ originX: isPresent ? 1 : 0 }}
        className="privacy-screen"
      />
    </div>
  );
}
