import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyB5laekq8PsKddbhgwWKT2dEswCpC36neM",
  authDomain: "naimun-merch.firebaseapp.com",
  projectId: "naimun-merch",
  storageBucket: "naimun-merch.appspot.com",
  messagingSenderId: "684352632406",
  appId: "1:684352632406:web:c002752f7ade486951edf7",
  measurementId: "G-G2X64GPKTK",
};

export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
