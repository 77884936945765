import React, { useState } from "react";
import { motion, useIsPresent } from "framer-motion";
import "../styles/login.css";
import { resetPassword } from "../helpers/auth";
import { useNavigate } from "react-router-dom";

export default function ResetPage() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const isPresent = useIsPresent();
  const navigate = useNavigate();

  return (
    <div className="loginPage">
      <div className="loginPageTitle">Forgot your password?</div>
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: isPresent ? 1 : 0, y: isPresent ? 0 : 50 }}
        transition={{ duration: 0.5 }}
        className="loginForm"
      >
        <div className="loginFormLabel">Email</div>
        <input
          className="loginFormInput"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="example@modelun.org"
        />
        <button
          className="loginFormButton"
          onClick={async () => {
            const resetError = await resetPassword(email);
            if (resetError !== undefined) {
              setError(resetError);
            } else {
              setError(null);
              navigate("/login");
            }
          }}
        >
          Reset Password
        </button>
        {error && <div className="loginFormError">{error}</div>}
      </motion.div>
      <motion.div
        initial={{ scaleX: 1 }}
        animate={{ scaleX: 0, transition: { duration: 0.5, ease: "circOut" } }}
        exit={{ scaleX: 1, transition: { duration: 0.5, ease: "circIn" } }}
        style={{ originX: isPresent ? 1 : 0 }}
        className="privacy-screen"
      />
    </div>
  );
}
