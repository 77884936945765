import React from "react";
import { useCookies } from "react-cookie";

export const CartContext = React.createContext({
  cart: [],
  total: 0,
  setCart: () => {},
  addToCart: () => {},
  removeFromCart: () => {},
  clearCart: () => {},
});

export function CartProvider({ children }) {
  const [cart, setCart] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [cookies, setCookie, updateCookies] = useCookies(["cart"]);

  const cacheCart = (cart) => {
    //persist the cart in the browser's cookies for 2 weeks
    setCookie("cart", cart, {
      path: "/",
      expires: new Date(Date.now() + 1209600000),
    });
    updateCookies();
  };

  React.useEffect(() => {
    if (cookies.cart) {
      setCart(cookies.cart);
      let newTotal = 0;
      for (let i = 0; i < cookies.cart.length; i++) {
        newTotal += cookies.cart[i].product.price * cookies.cart[i].quantity;
      }
      setTotal(newTotal);
    }
  }, []);

  const contextValue = {
    cart,
    setCart,
    addToCart: (product, size = "none") => {
      const newProduct = { ...product, size };
      if (
        cart.find(
          (item) =>
            item.product.id === newProduct.id && item.size === newProduct.size
        )
      ) {
        const newCart = cart.map((item) => {
          if (
            item.product.id === newProduct.id &&
            item.size === newProduct.size
          ) {
            item.quantity++;
          }
          return item;
        });
        setCart(newCart);
        setTotal(total + product.price);
        cacheCart(newCart);
      } else {
        const newCart = [...cart, { product, quantity: 1, size }];
        setCart(newCart);
        setTotal(total + product.price);
        cacheCart(newCart);
      }
    },
    removeFromCart: (index) => {
      const newCart = cart.filter((item, i) => i !== index);
      setCart(newCart);
      cacheCart(newCart);
      setTotal(
        newCart.reduce(
          (acc, item) => acc + item.product.price * item.quantity,
          0
        )
      );
    },
    clearCart: () => {
      setCart([]);
      setTotal(0);
      cacheCart([]);
    },
    changeQuantity: (index, quantity) => {
      const newCart = [...cart];
      newCart[index].quantity = quantity;
      setCart(newCart);
      cacheCart(newCart);
      setTotal(
        newCart.reduce(
          (acc, item) => acc + item.product.price * item.quantity,
          0
        )
      );
    },
  };

  return (
    <CartContext.Provider value={contextValue}>{children}</CartContext.Provider>
  );
}
