import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  sendPasswordResetEmail,
} from "firebase/auth";
import { app } from "./firebase";
import { addUser, getUser } from "./firestore";

export const auth = getAuth(app);

export const signIn = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (error) {
    console.error(error);
    let errorMessage = "";
    if (error.code === "auth/user-not-found") {
      errorMessage = "User not found";
    } else if (error.code === "auth/invalid-credential") {
      errorMessage = "We couldn't find an account with that email and password";
    } else {
      errorMessage = "An error occurred. Please try again later.";
    }
    return errorMessage;
  }
};

export const logout = async () => {
  try {
    await auth.signOut();
  } catch (error) {
    console.error(error);
  }
};

export const signUp = async (email, password) => {
  try {
    await createUserWithEmailAndPassword(auth, email, password);
    addUser(auth.currentUser.uid, email);
  } catch (error) {
    console.error(error);
  }
};

export const googleSignIn = async () => {
  try {
    const provider = new GoogleAuthProvider();
    const result = await signInWithPopup(auth, provider);
    /*check if user exists in database*/
    const user = await getUser(auth.currentUser.uid);
    if (!user) {
      addUser(auth.currentUser.uid, auth.currentUser.email).then(() => {
        window.location.reload();
      });
    }
  } catch (error) {
    console.error(error);
  }
};

export const resetPassword = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
  } catch (error) {
    console.error(error);
    if (error.code === "auth/user-not-found") {
      return "User not found";
    } else if (error.code === "auth/invalid-email") {
      return "Invalid email";
    }
    return "An error occurred. Please try again later.";
  }
};
