import React from "react";
import "../styles/about.css";
import { motion, useIsPresent } from "framer-motion";

export const RefundPage = () => {
  const isPresent = useIsPresent();
  return (
    <div className="aboutPage">
      <h1>NAIMUN LXII Merch Store Refund Policy</h1>
      <div className="aboutContent">
        <p>
          <strong>Effective Date:</strong> September 5, 2024
        </p>

        <h2>1. Preorder Refunds</h2>
        <p>
          <strong>Eligibility for Refunds:</strong> Refunds are available for
          items preordered from the NAIMUN LXII Merch Store up until February
          12th. To request a refund, please contact our support at{" "}
          <a href="mailto:l.ledlow@modelun.org">l.ledlow@modelun.org</a> before
          this date.
        </p>
        <p>
          <strong>Refund Process:</strong> Once your refund request is received
          and processed, a confirmation email will be sent to you. Refunds will
          be issued to the original payment method used for the purchase. Please
          allow up to 10 business days for the refund to be reflected in your
          account.
        </p>

        <h2>2. No Refunds After Conference Start</h2>
        <p>
          <strong>Deadline for Refunds:</strong> Refunds will not be issued for
          items ordered after February 12th. If you are unable to attend the
          conference or do not pick up your items during the event, the items
          will be considered forfeited, and no refunds or exchanges will be
          provided.
        </p>

        <h2>3. Exchange Policy</h2>
        <p>
          <strong>On-site Exchanges:</strong> Exchanges for items purchased
          through the merch store will only be available during the conference.
          Please visit our merch booth during the conference to request an
          exchange. Note that exchanges are subject to item availability and
          must be for the same item or of equal value.
        </p>

        <h2>4. Damaged or Incorrect Items</h2>
        <p>
          <strong>Report Issues:</strong> If you receive a damaged or incorrect
          item, please report the issue to our support team at{" "}
          <a href="mailto:l.ledlow@modelun.org">l.ledlow@modelun.org</a> or
          [support phone number] within 5 business days of receiving the item.
          We will work with you to resolve the issue promptly.
        </p>

        <h2>5. Contact Information</h2>
        <p>
          <strong>Support Team:</strong> For any questions or concerns regarding
          refunds, exchanges, or issues with your order, please contact us at:
        </p>
        <p>
          <strong>Email:</strong>{" "}
          <a href="mailto:l.ledlow@modelun.org">l.ledlow@modelun.org</a>
        </p>

        <p>
          Thank you for supporting NAIMUN LXII, and we look forward to seeing
          you at the conference!
        </p>
      </div>
      <motion.div
        initial={{ scaleX: 1 }}
        animate={{ scaleX: 0, transition: { duration: 0.5, ease: "circOut" } }}
        exit={{ scaleX: 1, transition: { duration: 0.5, ease: "circIn" } }}
        style={{ originX: isPresent ? 1 : 0 }}
        className="privacy-screen"
      />
    </div>
  );
};
