import React, { useEffect, useState } from "react";
import "../styles/home.css";
import { IoChevronForwardOutline } from "react-icons/io5";
import Carousel from "../components/carousel";
import { useNavigate } from "react-router-dom";
import { motion, useIsPresent } from "framer-motion";

const HomePage = () => {
  const navigate = useNavigate();
  const isPresent = useIsPresent();

  return (
    <div className="homePage">
      <div
        className="largeHomeCard"
        onClick={() => {
          navigate("/about");
        }}
      >
        <div className="largeHomeCardText">
          <div className="largeHomeCardSubtitle">
            Read about the inspiration for this year's merch
          </div>
          <div className="largeHomeCardTitle">Discover NAIMUN LXII</div>
        </div>
      </div>
      <div className="homeColumn">
        <div className="smallHomeCard" onClick={() => navigate("/browse")}>
          <div className="smallHomeCardAction">
            Browse Merch
            <IoChevronForwardOutline />
          </div>
          <div className="smallHomeCardText">
            Items are preorder only. Products will not be shipped after
            purchase, and must be picked up during the conference.
          </div>
        </div>
        <div
          className="smallHomeCard"
          style={{ backgroundColor: "#F7FBFD", cursor: "grab" }}
        >
          <Carousel slides={[0, 1, 2, 3]} options={{ loop: true }} />
        </div>
      </div>
      <motion.div
        initial={{ scaleX: 1 }}
        animate={{ scaleX: 0, transition: { duration: 0.5, ease: "circOut" } }}
        exit={{ scaleX: 1, transition: { duration: 0.5, ease: "circIn" } }}
        style={{ originX: isPresent ? 1 : 0 }}
        className="privacy-screen"
      />
    </div>
  );
};

export default HomePage;
