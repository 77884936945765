import React from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import "../styles/productCard.css";

export function ProductCard({ product }) {
  const navigate = useNavigate();
  return (
    <div>
      <div className="imageWrapper">
        <img
          src={`https://firebasestorage.googleapis.com/v0/b/naimun-merch.appspot.com/o/products%2Ffull%2F${product.image}_1728x2160?alt=media`}
          style={{
            backgroundImage: `url("https://firebasestorage.googleapis.com/v0/b/naimun-merch.appspot.com/o/products%2Fthumbnails%2F${product.image}_200x200?alt=media") no-repeat center center`,
          }}
          alt={product.name}
        />
      </div>
      <div className="productDetails">
        <div className="productTitle">{product.name}</div>
        <div className="productDescription">{product.description}</div>
        <div className="productPrice">${product.price}</div>
      </div>
    </div>
  );
}
